<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
	<el-row>
		<el-button type="primary" icon="el-icon-camera-solid" @click="callCamera()">开启摄像头</el-button>
		<!--确认-->
		<el-button  type="primary" @click="photograph">拍照</el-button>
	</el-row>
	<!--canvas截取流-->
	 <!--canvas截取流-->
	        <canvas ref="canvas" width="640" height="480"></canvas>

	<!--图片展示-->
	<video ref="video" width="640" height="480" autoplay></video>
	
  </div>
</template>

<script>
import qs from 'qs';
import axios from 'axios'

export default {
  name: 'HelloWorld',
  data() {
  	return {
  		msg: "Graduate Design",
		url: "https://graduate.wyxxwx.top/macapi/"
  	}
  },
  methods: {
  	callCamera() {
  		navigator.mediaDevices.getUserMedia({
  		                    video: true
						}).then(success => {
  		                    // 摄像头开启成功
  		                    this.$refs['video'].srcObject = success
  		                    // 实时拍照效果
  		                    this.$refs['video'].play()
  		}).catch(error => {
  		console.error('摄像头开启失败，请检查摄像头是否可用！')
		})						
  	},
	photograph () {
	                let ctx = this.$refs['canvas'].getContext('2d')
	                // 把当前视频帧内容渲染到canvas上
	                ctx.drawImage(this.$refs['video'], 0, 0, 640, 480)
	                // 转base64格式、图片格式转换、图片质量压缩---支持两种格式image/jpeg+image/png
	                let imgBase64 = this.$refs['canvas'].toDataURL('image/jpeg', 0.7)
	 
	                /**------------到这里为止，就拿到了base64位置的地址，后面是下载功能----------*/
	 
	                // 由字节转换为KB 判断大小
	                let str = imgBase64.replace('data:image/jpeg;base64,', '')
	                let strLength = str.length
	                let fileLength = parseInt(strLength - (strLength / 8) * 2)　　　 // 图片尺寸  用于判断
	                let size = (fileLength / 1024).toFixed(2)
	                // console.log(size) 　　  // 上传拍照信息  调用接口上传图片 .........
					this.uploadPic(str)
	            },
	uploadPic(picbase){
		let that = this;
		var user={
					picBase64: picbase
				}
		axios({
			url: that.url + 'pic/identify',
			method:'post',
			data: user,  //这里json对象会转换成json格式字符串发送
			header:{
			'Content-Type':'application/json'  //如果写成contentType会报错,如果不写这条也报错
			//Content type 'application/x-www-form-urlencoded;charset=UTF-8'...
			}			
		})
		.then(res=>{
		    console.log(res.data)
		})
		.catch(Error=>{
		    console.log(Error)
		})
		
	},
		
	
  },
}	
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
